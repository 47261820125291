import React, { useState } from "react";
import Table from "../generic/Table/Table";
import { PartnerInterface } from "../../interfaces/PartnerInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faShop, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/generic/AuthContext";

interface PartnersTableProps {
  partners: PartnerInterface[];
  onEdit: (partner: PartnerInterface) => void;
  onEditShops: (partner: PartnerInterface) => void;
  onDelete: (partner: PartnerInterface) => void;
}

const PartnersTable: React.FC<PartnersTableProps> = ({
  partners,
  onEdit,
  onEditShops,
  onDelete,
}) => {
  const { user } = useAuth();
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  const headers = [
    "Nom",
    "Email",
    "Manager",
    //"Grande Distribution",
    "Commentaire",
    "Action",
  ];

  const partnerData = partners.map((partner) => ({
    Nom: partner.name,
    "Email": partner.email,
    "Manager": partner?.manager?.login,
    //"Grande Distribution": partner.isMajorRetailer ? "Oui" : "Non",
    Commentaire: partner.comment,
    Action: partner.id ? (
      <>
        {isAdmin && (
          <>
            <button onClick={() => onEdit(partner)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </>
        )}
        <button onClick={() => onEditShops(partner)}>
          <FontAwesomeIcon icon={faShop} />
        </button>
        {isAdmin && (
          <>
            <button className="btn-delete" onClick={() => onDelete(partner)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        )}
      </>
    ) : (
      <></>
    ),
  }));

  return (
    <Table
      headers={headers}
      columnWidths={["auto", "auto", "10em"]}
      headerAlignments={["left", "left", "center"]}
      contentAlignments={["left", "left", "center"]}
      data={partnerData}
    />
  );
};

export default PartnersTable;
