import React from "react";
import Table from "../generic/Table/Table";
import { ShopInterface } from "../../interfaces/ShopInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/generic/AuthContext";

interface ShopsTableProps {
  shops: ShopInterface[];
  onEdit: (shop: ShopInterface) => void;
  onDelete: (shop: ShopInterface) => void;
}

const ShopsTable: React.FC<ShopsTableProps> = ({
  shops,
  onEdit,
  onDelete,
}) => {
  const { user } = useAuth();
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  const { t: tProviders } = useTranslation('providers');
  const headers = [
    "Partenaire",
    "Account Manager",
    "Nom",
    "Adresse",
    "Tel.",
    "Fournisseurs",
    "Commentaire",
    "Action",
  ];

  // Safely handle possibly undefined shops array
  const shopData = (shops || []).map((shop) => ({
    Partenaire: shop.partner?.name || "",  // Optional chaining with default
    "Account Manager": shop?.partner?.manager?.login || "",
    Nom: shop.name || "",
    Adresse: shop.address || "",
    "Tel.": shop.phoneNumber || "",
    Fournisseurs: (shop.shopProviders || []).length > 0 ? (
      <ul style={{ margin: 0 }}>
        {shop.shopProviders.map((provider, index) => (
          <li key={index} style={{ margin: 0, padding: 0 }}>
            {`${provider.customName} (${tProviders(provider.provider)})`}
          </li>
        ))}
      </ul>
    ) : "",
    Commentaire: shop.comment || "",
    Action: shop.id ? (
      <>
        {isAdmin && (
          <>
            <button onClick={() => onEdit(shop)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button className="btn-delete" onClick={() => onDelete(shop)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        )}
      </>
    ) : null, // Use null instead of React Fragment for no output
  }));

  return (
    <Table
      headers={headers}
      columnWidths={["auto", "auto", "auto", "12em", "auto", "auto", "auto", "7em"]}
      headerAlignments={["left", "left", "left", "left", "left", "left", "left", "center"]}
      contentAlignments={["left", "left", "left", "left", "left", "left", "left", "center"]}
      data={shopData}
    />
  );
};

export default ShopsTable;
