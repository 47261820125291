import { ApiService, request } from "./generic/apiService";
import { ShopInterface } from "../interfaces/ShopInterface";
import { PartnerInterface, SummaryAutomationExecutionInterface } from "../interfaces/PartnerInterface";
import { formatDateToLocalISO } from "../utils/generic/dateUtils";
import { LogsInterface } from "../components/Automations/generic/Interfaces/LogsInterface";

export interface AutomationLaunchParamsInterface {
  force: boolean;
  manager?: string | null;
}
export interface AutomationStopParamsInterface {
  manager?: string | null;
}
export class ApiAutomationService extends ApiService<ShopInterface, ShopInterface> {
  constructor() {
    super(`/automations`);
  }

  toggle = async (shop: ShopInterface, automationCode: string, isChecked: boolean): Promise<ShopInterface | null> => {
    try {
      let response = await request({
        url: `${this.basePath}/shop/${shop.id}/toggle/${automationCode}`,
        method: 'POST',
      });
      return response.data;
    } catch (error) {
      console.error('Error during file upload:', error);
      throw error;
    }
  };

  summaryLaunch = async (partner: PartnerInterface | null, startDate: Date, endDate: Date, simulate: boolean, params: AutomationLaunchParamsInterface): Promise<SummaryAutomationExecutionInterface | null> => {
    try {
      // Construire la base de l'URL
      let url = `${this.basePath}/summary/${simulate ? "simulate" : "launch"}/${partner ? partner.id + "/" : ""}${formatDateToLocalISO(startDate)}/${formatDateToLocalISO(endDate)}`;
  
      // Ajouter les paramètres de requête
      let queryParams = new URLSearchParams();
      if (params.force) {
        queryParams.append('force', '1');
      }
      if (params.manager) {
        queryParams.append('manager', params.manager);
      }
  
      // Ajouter les paramètres de requête à l'URL
      if (queryParams.toString()) {
        url += `?${queryParams.toString()}`;
      }
  
      let response = await request({
        url: url,
        method: 'PUT'
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  summaryForce = async (executionLog: LogsInterface): Promise<SummaryAutomationExecutionInterface | null> => {
    try {
      let response = await request({
        url: `${this.basePath}/summary/execution/${executionLog.id}/force`,
        method: 'PUT',
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  summaryStop = async (partner?: PartnerInterface, params?: AutomationStopParamsInterface): Promise<null> => {
    try {
      let idToStop = partner?.lastSummaryAutomationExecution?.id;
      let url = `${this.basePath}/summary/${idToStop ? idToStop + "/" : ""}stop`;
      
      // Ajouter les paramètres de requête
      let queryParams = new URLSearchParams();
      if (params?.manager) {
        queryParams.append('manager', params.manager);
      }
  
      // Ajouter les paramètres de requête à l'URL
      if (queryParams.toString()) {
        url += `?${queryParams.toString()}`;
      }

      let response = await request({
        url: url,
        method: 'PUT',
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getLogs = async (partner: PartnerInterface): Promise<SummaryAutomationExecutionInterface[]> => {
    try {
      let response = await request({
        url: `${this.basePath}/summary/${partner.id}/logs`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}
