import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Table from "../generic/Table/Table";
import CheckboxField from "../generic/Form/Fields/Checkbox";
import { ShopInterface } from "../../interfaces/ShopInterface";
import { ShopAutomationInterface } from "../../interfaces/AutomationInterface";
import { useTranslation } from "react-i18next";

interface ShopsTableProps {
  shops: ShopInterface[];
  automations: string[];
  onToggle: (shop: ShopInterface, automationCode: string, isChecked: boolean) => void;
}

const ShopsTable: React.FC<ShopsTableProps> = ({
  shops,
  automations,
  onToggle,
}) => {
  
  const [translatedAutomations, setTranslatedAutomations] = useState<string[]>([]);
  const { t: tAutomations } = useTranslation('automations');
  const methods = useForm();

  useEffect(() => {
    setTranslatedAutomations(automations.map(automation => tAutomations(`codes.${automation}`)));
  }, [automations]);

  const headers = [
    "Partenaire",
    "Manager",
    "Nom",
    ...translatedAutomations
  ];

  const shopData = (shops || []).map((shop, shopIndex) => {
    const automationsData = automations.reduce((acc, automation) => {
      const hasAutomation = shop.automations.some(
        (auto: ShopAutomationInterface) => auto.code === automation
      );
      acc[tAutomations(`codes.${automation}`)] = (
        <CheckboxField
          name={`shops[${shopIndex}].automations[${automation}]`}
          value={automation}
          checked={hasAutomation}
          disabled={false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onToggle(shop, automation, event.target.checked)}
        />
      );
      return acc;
    }, {} as { [key: string]: React.ReactNode });

    return {
      Partenaire: shop.partner?.name || "",
      Manager: shop.partner?.manager?.email || "",
      Nom: shop.name || "",
      ...automationsData
    };
  });

  return (
    <FormProvider {...methods}>
      <form>
        <Table
          headers={headers}
          columnWidths={Array(headers.length).fill("auto")}
          headerAlignments={Array(headers.length).fill("left")}
          contentAlignments={Array(headers.length).fill("left")}
          data={shopData}
        />
      </form>
    </FormProvider>
  );
};

export default ShopsTable;
