import { UserInterface } from "./UserInterface";

export interface PartnerInterface {
    id: number;
    name: string;
    email: string;
    manager: UserInterface;
    isMajorRetailer: boolean;
    comment: string;
    lastSummaryAutomationExecution: SummaryAutomationExecutionInterface | null;
}

export interface SummaryAutomationExecutionInterface {
    id: number;
    startDate: string;
    endDate: string;
    status: AutomationExecutionStatusEnum;
    createdAt: string;
    updatedAt: string | null;
    simulate: boolean;
}


export enum AutomationExecutionStatusEnum {
    PENDING = 'pending',
    RUNNING = 'running',
    COMPLETED = 'completed',
    FAILED = 'failed',
    MANUALLY_STOPPED = "manually_stopped"
}