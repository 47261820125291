import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import InputField from "../../../generic/Form/Fields/Input";
import { UserInterface } from "../../../../interfaces/UserInterface";
import SelectField from "../../../generic/Form/Fields/Select";
import { useUserManager } from "../../../../hooks/useUserManager";

interface Props {
  user?: UserInterface | null;
  onSubmit: (data: any) => void;
}

const UserFormEdit: React.FC<Props> = ({ onSubmit, user }) => {
  const methods = useForm();
  const {getHigestCurrentRole} = useUserManager({reloadUsers: undefined});

  React.useEffect(() => {
    methods.reset({
      email: !user ? "" : user.email,
      login: !user ? "" : user.login,
      role: getHigestCurrentRole(user)
    });
  }, [user]);

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newRoles = [...(user?.roles || []), methods.getValues('role')];
    
    methods.setValue('roles', newRoles); // Mise à jour du tableau de rôles dans le formulaire
  };

  const options = [
    {value:"ROLE_ADMIN", label:"Administrateur"},
    {value:"ROLE_ACCOUNT_MANAGER", label:"Account Manager"},
    {value:"ROLE_SALES", label:"Commercial"}
  ]

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField
          name="email"
          label="Email"
          type="email"
          validationRules={{ required: "Ce champ est requis" }}
        />
        <InputField
          name="login"
          label="login"
          type="text"
          validationRules={{ required: "Ce champ est requis" }}
        />

        <SelectField
          name="role"
          label="Rôle"
          options={options}
          defaultValue={getHigestCurrentRole(user) ?? "ROLE_ADMIN"}
          validationRules={{ required: "Ce champ est requis" }}
          onChange={handleRoleChange}
        />
        <div className="buttons-container">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    </FormProvider>
  );
};

export default UserFormEdit;