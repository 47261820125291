import React, { useEffect, useState } from "react";
import ShopsTable from "./ShopsTable";
import { ShopInterface } from "../../interfaces/ShopInterface";
import { ApiService } from "../../services/generic/apiService";
import { PartnerInterface } from "../../interfaces/PartnerInterface";
import { ApiAutomationService } from "../../services/apiAutomationService";

interface ShopListProps{
  partner?: PartnerInterface
}

const ShopsList: React.FC<ShopListProps> = ({partner}) => {
  const apiShopsService = new ApiService<ShopInterface, ShopInterface>('/shops');
  const apiAutomationsCodesService = new ApiService<string, string>('/automations/codes');
  const apiAutomationService = new ApiAutomationService();
  // State hooks
  const [shops, setShops] = useState<ShopInterface[]>([]);
  const [automations, setAutomations] = useState<string[]>([]);

  const getShops = async () => {
    setShops(await apiShopsService.get(undefined, partner ? { partnerId: partner.id.toString()} : undefined));
  }

  const getAutomations = async () => {
    setAutomations(await apiAutomationsCodesService.get());
  }

  useEffect(() => {
    getShops();
    getAutomations();
  }, []);

  const handletoggleAutomation = (shop: ShopInterface, automationCode: string, isChecked: boolean) => {
    apiAutomationService.toggle(shop, automationCode, isChecked );
  };

  return (
    <>
      <ShopsTable
        shops={shops}
        automations={automations}
        onToggle={handletoggleAutomation}
      />
    </>
  );
};

export default ShopsList;
