import React, { useCallback } from "react";
import { ApiPartnerService } from "../../../services/apiPartnerService";
import { RegisterOptions } from "react-hook-form";
import SelectFetcher from "../../generic/Form/Fields/SelectFetcher";

interface PartnerSelectProps {
  name: string;
  label: string;
  defaultValue?: string
  validationRules?: RegisterOptions;
}

interface PartnerOptionInterface {
    value: string;
    label: string;
}

const PartnerSelect: React.FC<PartnerSelectProps> = ({
  name,
  label,
  defaultValue,
  validationRules,
}) => {
  const apiPartnerService = new ApiPartnerService();
  const loadPartners = useCallback(async () => {
    const partners = await apiPartnerService.get();
    let options: PartnerOptionInterface[] = [];

    partners.forEach(partner => {
      options.push({ value: partner.id.toString(), label: partner.name });
    })
    return options;
  }, []);

  return (
    <SelectFetcher
      name={name}
      label={label}
      loadOptionsFunction={loadPartners}
      defaultValue={defaultValue}
      validationRules={validationRules}
    />
  );
};

export default PartnerSelect;
